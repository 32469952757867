<template>
  <div class="AddDialog">
    <el-dialog
      title="添加"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="领导人ID">
              <el-input
                v-model="ruleForm.uid"
                placeholder="请输入领导人ID"
              ></el-input>
            </el-form-item>
          </el-col>
          
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ownercreate } from "@/request/api";
export default {
  name: "AddDialog",
  components: {},
  data() {
    return {
      id: "",
      token: "",
      rolesList: [], //角色列表
      type: 1, //1新增，2编辑
      dialogVisible: false,
      ruleForm: {
        uid:""
      },
      rules: {
        uid: [
          { required: true, message: "领导人ID不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created: function () {
    
  },
  mounted: function () {},
  methods: {
    //获取修改的信息
    getUserEdit() {},
    show() {
    //   this.type = type;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate(); //关闭清空校验规则
      });
    },
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          
            let token = sessionStorage.getItem("token");
            this.token = token;
            let params = {
              token: sessionStorage.getItem("token"),
              uid:this.ruleForm.uid
            };

            ownercreate(params).then((res) => {
              if (res.data.code == 200) {
                this.$message.success("新增成功");
                this.close();
                this.$parent.getUserList();
              } else {
                this.$message.error(res.data.msg);
                this.close();
                this.$parent.getUserList();
              }
            });
          
        //   this.$parent.getUserList();
          this.close();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>
