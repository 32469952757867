<template>
  <div class="user">
   <div class="block-quote">
      <el-form :inline="true">
        <el-form-item style="float: right">
          <el-button type="primary" @click="add">添加</el-button>
        </el-form-item>
      </el-form>
    </div>

    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="uid" label="领导人ID" align="center">
      </el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center">
      </el-table-column>
      <el-table-column prop="sta" label="状态" align="center">
        <!-- <template slot-scope="scope">
          <el-link type="success" v-if="scope.row.sta == 1">启用</el-link>
          <el-link type="danger" v-if="scope.row.sta == 2"
            >禁用</el-link
          >
        </template> -->
         <template slot-scope="scope">
          <el-switch
            v-model="scope.row.sta"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="changeSwitch(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" align="center">
      </el-table-column>
      <!-- <el-table-column prop="deleted_at" label="发放时间" align="center"> 
      </el-table-column> -->
    </page-table>
    <!-- 新增编辑弹窗 -->
    <edit-data ref="editData"></edit-data>
  </div>
</template>

<script>
import { ownerindex ,owneredit} from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import editData from './components/edit'
import pageTable from "@/components/pageTable.vue";

export default {
  name: "user",
  components: {
    pageTable,
    editData
  },
  data() {
    return {
      userList: [], // 用户列表

      goods_name: "",
      type: "",
      time: "",
      ex_status: "",

      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
  },
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    add() {
      this.$refs.editData.show();
    },
    // editData(type, row) {
    //   let rowData = row;
    //   // 1:新增，2:编辑
    //   this.$refs.editData.show(2, JSON.parse(JSON.stringify(rowData)));
    // },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },

    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
        let params = {
          token: this.token,
          limit: this.page.pageSize,
          page: this.page.currentPage
        }
        ownerindex(params).then((res) => {
        console.log(res)
        this.userList = res.data.data
        this.page.total=res.data.count
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      })
      },
    // 启用/禁用
    changeSwitch(row){
        console.log(row)
        let params = {
            token: sessionStorage.getItem("token"),
            id: row.id,
            sta:row.sta
        }
        owneredit(params).then((res) => {
            console.log(res)
            if (res.data.code == 200) {
                this.$message.success("操作成功");
            }
            else {
                this.$message.error(res.data.msg);
              }
        })

    }
  },
};
</script>

<style lang="scss" scoped>
</style>
